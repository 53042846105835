<template>
  <div class="step-page">
    <searchForm ref="searchForm" @search="handleSearch" />
    <p-table
      ref="table"
      row-key="id"
      :scroll="{ y: 0, x: 1900 }"
      extraHeight="530"
      :sourceData="getDataApi"
      download
      :columns="columns"
      :rowSelection="rowSelection"
    >
      <div slot="headerLeft" class="table-header-left">
        <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleSubmit" v-perms="'add'">保险购买</a-button>
        <span class="tips">已选择人员数（*保单人员上限为50人）</span>
        <span class="userNum">{{ selectedRowKeys.length }}</span>
      </div>
      <template slot="action" slot-scope="text, record">
        <a-button type="link" @click="handlePayForUser(record)" v-perms="'add'">购买</a-button>
      </template>
    </p-table>
  </div>
</template>

<script>
import searchForm from './search-form'
import { getInsuranceUser, postInsuranceUser, getOrderInfoByNo } from '@/api/insurance'
import _ from 'lodash'
import { getDictListByNames } from '@/api/employmentSupport/dict'
import moment from 'moment'

export default {
  name: 'StepPage',
  components: {
    searchForm
  },
  computed: {
    rowSelection () {
      const { selectedRowKeys } = this
      return {
        columnWidth: '60',
        selectedRowKeys,
        fixed: true,
        onChange: this.handleTableSelectChange
      }
    },
    columns () {
      const type = this.$route.query.productFlag
      const column =
        type === 'TZFG'
          ? [
              {
                title: '保险状态',
                dataIndex: 'insuranceStatus',
                key: 'insuranceStatus',
                customRender: (t) => {
                  return this.statusOptions.find((item) => item.value == t)?.label || '保险未购买'
                },
                ellipsis: true,
                download: true
              },
              {
                title: '起保时间',
                dataIndex: 'effectiveDate',
                key: 'effectiveDate',
                ellipsis: true,
                download: true
              },
              {
                title: '终保时间',
                dataIndex: 'expiryDate',
                key: 'expiryDate',
                ellipsis: true,
                download: true
              }
            ]
          : [
              {
                title: '保险状态',
                dataIndex: 'insuranceStatus2',
                key: 'insuranceStatus2',
                customRender: (t) => {
                  return this.statusOptions.find((item) => item.value == t)?.label || '保险未购买'
                },
                ellipsis: true,
                download: true
              },
              {
                title: '起保时间',
                dataIndex: 'effectiveDate2',
                key: 'effectiveDate2',
                ellipsis: true,
                download: true
              },
              {
                title: '终保时间',
                dataIndex: 'expiryDate2',
                key: 'expiryDate2',
                ellipsis: true,
                download: true
              }
            ]
      return [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          fixed: 'left',
          width: 60,
          customRender: (t, r, i) => i + 1,
          download: true
        },
        {
          title: '姓名',
          dataIndex: 'realName',
          key: 'realName',
          fixed: 'left',
          width: 80,
          download: true
        },
        {
          title: '身份证号',
          dataIndex: 'idCard',
          fixed: 'left',
          key: 'idCard',
          ellipsis: true,
          width: 160,
          download: true
        },
        ...column,
        {
          title: '性别',
          dataIndex: 'sex',
          key: 'sex',
          customRender: (t) => (t === 0 ? '男' : '女'),
          width: 60,
          ellipsis: true,
          download: true
        },
        {
          title: '年龄',
          dataIndex: 'age',
          key: 'age',
          width: 60,
          ellipsis: true,
          download: true
        },
        {
          title: '联系电话',
          dataIndex: 'phone',
          key: 'phone',
          width: 120,
          ellipsis: true,
          download: true
        },

        {
          title: '项目名称',
          dataIndex: 'projectName',
          key: 'projectName',
          ellipsis: true,
          download: true
        },
        {
          title: '项目部名称',
          dataIndex: 'projectDepartmentName',
          key: 'projectDepartmentName',
          ellipsis: true,
          download: true
        },
        {
          title: '工种/专业',
          dataIndex: 'positionName',
          key: 'positionName',
          width: 300,
          customRender: (t, r) => t + `【${moment(r.demandCreateTime).format('YYYY/MM/DD')}】`,
          ellipsis: true,
          download: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 140,
          fixed: 'right',
          key: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  data () {
    return {
      selectedRowKeys: [],
      tableData: [],
      handleSearch: () => {},
      statusOptions: []
    }
  },
  mounted () {
    this.handleSearch = _.debounce(
      () => {
        this.$refs.table.refresh()
      },
      300,
      {
        leading: false,
        trailing: true
      }
    )
    this.initDict()
  },
  methods: {
    initDict () {
      getDictListByNames(['insurance_status']).then((res) => {
        this.statusOptions = res.data[0].detail.sort((a, b) => a.value - b.value)
      })
    },
    handleTableSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    getDataApi (params) {
      const formSearch = this.$refs.searchForm.getQuery()
      return getInsuranceUser({
        ...params,
        ...formSearch,
        type: this.$route.query.productFlag
      })
    },
    handleSubmit () {
      console.log(this.$store.getters)
      if (this.selectedRowKeys.length > 50) {
        this.$message.error('保单人员上限为50人')
      } else {
        // const tableData = this.$refs.table.tableData
        // const rowData = tableData
        //   .filter((item) => this.selectedRowKeys.includes(item.id))
        //   .map((item, index) => ({
        //     age: item.age,
        //     enterpriseId: this.$store.getters.userInfo.enterpriseInfoDto.enterpriseId,
        //     // enterpriseId: item.enterpriseId,
        //     idCard: item.idCard,
        //     personIdType: '111',
        //     positionId: item.positionId,
        //     realName: item.realName,
        //     signId: item.id
        //   }))
        this.$spin.show()
        postInsuranceUser(
          this.selectedRowKeys.map((item) => ({ signId: item, productFlag: this.$route.query.productFlag }))
        )
          .then((res) => {
            const { producturl, serialNo, accessToken: token, usercode } = res.responseBody
            window.open(`${producturl}&personBatchNo=${serialNo}&usercode=${usercode}&token=${token}`, '_blank')
            this.handleConfirm(serialNo)
          })
          .finally(() => {
            this.$spin.hide()
          })
      }
    },
    handlePayForUser (row) {
      const rowData = [
        {
          signId: row.id,
          productFlag: this.$route.query.productFlag
        }
      ]
      this.$spin.show()
      postInsuranceUser(rowData)
        .then((res) => {
          const { producturl, serialNo, accessToken: token, usercode } = res.responseBody
          window.open(`${producturl}&personBatchNo=${serialNo}&usercode=${usercode}&token=${token}`, '_blank')
          this.handleConfirm(serialNo)
        })
        .finally(() => {
          this.$spin.hide()
        })
    },
    handleConfirm (serialNo) {
      this.$confirm({
        title: '订单创建',
        width: 720,
        content: (
          <div>
            <div>请在新标签页中进行保单创建，（您是否已创建订单）？</div>
            <div style="color: red;margin-top: 5px">
              友情提示：【部分浏览器可能会拦截新标签页的打开，如未正常打开页面，请设置浏览器允许弹窗】
            </div>
          </div>
        ),
        onOk: () => {
          return new Promise((resolve, reject) => {
            getOrderInfoByNo(serialNo)
              .then((res) => {
                if (res.data) {
                  this.$router.replace({
                    path: '/insurance/insurance-create',
                    query: {
                      orderNo: res.data.orderNo
                    }
                  })
                  setTimeout(() => {
                    this.$eventBus.$emit('updateStatus')
                  })
                } else {
                  this.$message.error('未查询到您创建的保险订单信息')
                }
              })
              .finally(() => {
                resolve()
              })
          })
        }
      })
    }
  }
}
</script>

<style lang="less">
.step-page {
  padding-top: 20px;
  .table-header-left {
    display: flex;
    align-items: center;
    .tips,
    .userNum {
      display: inline-block;
      margin-left: 12px;
    }
  }
}
</style>
